import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation } from "react-router-dom";

import AuthService from "../../services/auth.services";
import useStore from "../../store/modalSubs";
import ModalSubscribe from "../../components/Modal/ModalSubscribe";
import { PrimaryButton, SecondaryButtonOutlined } from "../Button/Button";
import { dataLogo } from "../../data/packageData";
import "./navbar.css";

function Navbar({ isToken, setIsToken, isLoading }) {
  const location = useLocation();
  const isRoot = window.location.pathname === "/";
  const currentUser = AuthService.getCurrentUser();
  const history = useHistory();
  const [progress, setProgress] = useState(0); //LINEAR PROGRESS
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdownProfileVisible, setDropdownProfileVisible] = useState(false);
  const setModal = useStore((state) => state.setIsModal);
  const modal = useStore((state) => state.isModal);

  const shouldRenderLoginButton = () => {
    const excludedPaths = ["/project"];
    return !excludedPaths.some((path) => location.pathname.startsWith(path));
  };

  const shouldRenderForm =
    location.pathname === "/" || location.pathname === "/monitoring";

  const logoutHandle = () => {
    AuthService.logout();
    history.replace("/signin");
    setIsToken(false);
  };

  const toggleDropdownProfile = () => {
    setDropdownProfileVisible(!dropdownProfileVisible);
  };

  useEffect(() => {
    if (currentUser) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [currentUser, setIsToken]);

  useEffect(() => {
    if (isLoading) {
      setProgress(0);
    }
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1350);
    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  if (modal) {
    return <ModalSubscribe />;
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {(currentUser || isRoot) && shouldRenderForm && (
        <div className="linear-progress-nav">
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
      <div className="navbar-person">
        <div className="suar-logo">
          <Link to="/">
            <img src={dataLogo.logo} alt="logo" width="140px" />
          </Link>
        </div>
        <div className={`menu-right ${isMobileMenuOpen ? "open" : ""}`}>
          {isToken || currentUser ? (
            <div className="icon-login">
              <Link
                to="#"
                className="avatar-user"
                onClick={toggleDropdownProfile}
              >
                <AccountCircleIcon />
              </Link>
              {dropdownProfileVisible && (
                <div className="dropdown-menu">
                  <Link onClick={toggleDropdownProfile} to="/profile">
                    Profile
                  </Link>
                  <Link onClick={toggleDropdownProfile} to="/monitoring">
                    Project
                  </Link>
                </div>
              )}
              <span title="Logout" className="avatar-user">
                <ExitToApp onClick={logoutHandle} />
              </span>
            </div>
          ) : (
            <>
              {shouldRenderLoginButton() && (
                <div className="icon-login" title="Login">
                  <div className="btn-subscribe">
                    <a href="/#" onClick={() => setModal()}>
                      <p>Package</p>
                    </a>
                  </div>
                  <Link to="/signin" className="menu-item">
                    <span title="login">
                      <SecondaryButtonOutlined>Login</SecondaryButtonOutlined>
                    </span>
                  </Link>
                  <Link to="/signup" className="menu-item">
                    <span title="login">
                      <PrimaryButton>Sign up free</PrimaryButton>
                    </span>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div>
      </div>
      {/* <div className="navbar-person">
        <div className="suar-logo">
          <Link to="/">
            <img src="assets/suar.svg" alt="logo" width="140px" />
          </Link>
        </div>
        <div className="menu-right">
          {isToken || currentUser ? (
            <div className="icon-login">
              <Link to="/monitoring">
                <span title="Monitoring">{currentUser.success?.name}</span>
              </Link>
              <span title="Logout">
                <ExitToApp onClick={logoutHandle} fontSize="small" />
              </span>
            </div>
          ) : (
            <>
              <div className="icon-login" title="Login">
                <div className="btn-subscribe">
                  <a href="/#" onClick={() => setModal()}>
                    <p>Package</p>
                  </a>
                </div>
                <Link to="/signin" className="menu-item">
                  <span title="login">
                    <SecondaryButtonOutlined>Login</SecondaryButtonOutlined>
                  </span>
                </Link>
                <Link to="/signup" className="menu-item">
                  <span title="login">
                    <PrimaryButton>Sign up free</PrimaryButton>
                  </span>
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div>
      </div> */}
    </>
  );
}

export default Navbar;
